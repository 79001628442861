//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';
import styled          from 'styled-components';

import ImageHelper          from '@helper/ImageHelper';
import UrlHelper            from '@helper/Url';
import useContextTranslator from '@hooks/ContextTranslator';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import Icon                 from '@stateless/atomic/Icon';
import IconType             from '@stateless/atomic/Icon/IconType';
import Separator            from '@stateless/atomic/Separator';
import LayoutScreen         from '@stateless/composed/LayoutScreen';
import PageTitle            from '@stateless/composed/PageTitle';
import selectObjectPools    from '@store/selectors/objectPool';

import styles from './styles.module.scss';

/* eslint-disable rulesdir/no-repeatedly-props-use-constant */
const ThemedLink = styled.a`
    &:hover
    {
        color: ${(props) => props.theme.secondaryColor} !important;

        svg
        {
            color: ${(props) => props.theme.secondaryColor} !important;
        }
    }
`;
/* eslint-enable rulesdir/no-repeatedly-props-use-constant */

const ObjectPoolsScreen = () => {
    const translator  = useContextTranslator('screens.objectPoolsScreen');
    const objectPools = useSelector(selectObjectPools);

    function renderIconLink(iconType, url, text) {
        return (
            <ThemedLink href={url}>
                <Icon iconType={iconType} />
                {text}
            </ThemedLink>
        );
    }

    function renderPhone(objectPool) {
        return renderIconLink(
            IconType.phone,
            UrlHelper.toTelUrl(objectPool.phone),
            objectPool.phone,
        );
    }

    function renderMail(objectPool) {
        return renderIconLink(
            IconType.mail,
            UrlHelper.toMailToUrl(objectPool.email),
            objectPool.email,
        );
    }

    function renderObjectPool(objectPool) {
        const objectPoolName = objectPool.name;
        const imageSrc       = ImageHelper.getImagePath(objectPool.image);
        const imageAlt       = `${objectPoolName}_image`;

        return (
            <div className={styles.objectPoolItem}>
                <img
                    src={imageSrc}
                    alt={imageAlt}
                />
                <Headline
                    title={objectPoolName}
                    type={HeadlineType.headline3}
                />
                <p>
                    {objectPool.description}
                </p>
                <Separator />
                <div className={styles.contactWrapper}>
                    {renderMail(objectPool)}
                    {renderPhone(objectPool)}
                </div>
            </div>
        );
    }

    function renderObjectPools() {
        return _.map(objectPools, renderObjectPool);
    }

    return (
        <>
            <PageTitle
                title={I18n.t('objectPools')}
            />
            <LayoutScreen>
                <div className={styles.objectPoolsScreen}>
                    <Headline
                        type={HeadlineType.headline1}
                        title={translator.t('headline')}
                    />
                    <p>
                        {translator.t('text')}
                    </p>
                    <div className={styles.objectPoolsWrapper}>
                        {renderObjectPools()}
                    </div>
                </div>
            </LayoutScreen>
        </>
    );
};

export default ObjectPoolsScreen;
