//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useHistory } from 'react-router';

import Routes               from '@constants/Routes';
import useContextTranslator from '@hooks/ContextTranslator';
import IconType             from '@stateless/atomic/Icon/IconType';
import CtaBanner            from '@stateless/composed/CtaBanner';

const ObjectPoolBanner = () => {
    const { t }   = useContextTranslator('components.propertyPoolBanner');
    const history = useHistory();

    function onButtonClicked() {
        history.push(Routes.objectPools);
    }

    return (
        <CtaBanner
            title={t('title')}
            content={t('paragraphs', {
                returnObjects: true,
            })}
            buttonText={t('buttonText')}
            buttonIconType={IconType.circlePlus}
            onButtonClicked={onButtonClicked}
        />
    );
};

export default ObjectPoolBanner;
