//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useMemo } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';

import Routes             from '@constants/Routes';
import { useCalculation } from '@slices/calculation';
import IconType           from '@stateless/atomic/Icon/IconType';

const useNavigationItems = (history) => {
    const currentRoute       = history.location.pathname;
    const dispatch           = useDispatch();
    const calculationActions = useCalculation(dispatch);

    function onNavItemClicked(route, disabled = false) {
        return () => {
            if (!disabled) {
                history.push(route);
            }
        };
    }

    function onCalculationClicked() {
        const isCalculationRoute = _.startsWith(currentRoute, Routes.calculation);

        if (!isCalculationRoute) {
            calculationActions.openCalculation();
        }
    }

    const navigationItems       = useMemo(
        () => {
            const isCalculationRoute = _.startsWith(currentRoute, Routes.calculation);
            const isObjectRoute      = _.startsWith(currentRoute, Routes.objects);
            const isCustomerRoute    = _.startsWith(currentRoute, Routes.customers);
            const isObjectPoolRoute  = _.startsWith(currentRoute, Routes.objectPools);

            return [
                {
                    iconType: IconType.pieChart,
                    text:     I18n.t('dashboard'),
                    route:    Routes.dashboard,
                    isActive: currentRoute === Routes.dashboard,
                    onClick:  onNavItemClicked(Routes.dashboard),
                },
                {
                    iconType: IconType.home,
                    text:     I18n.t('objects'),
                    route:    Routes.objects,
                    isActive: isObjectRoute,
                    onClick:  onNavItemClicked(Routes.objects),
                },
                {
                    iconType: IconType.user,
                    text:     I18n.t('customers'),
                    route:    Routes.customers,
                    isActive: isCustomerRoute,
                    onClick:  onNavItemClicked(Routes.customers),
                },
                {
                    iconType: IconType.clipboard,
                    text:     I18n.t('calculation'),
                    route:    Routes.calculationCustomer,
                    isActive: isCalculationRoute,
                    onClick:  onCalculationClicked,
                },
                {
                    iconType: IconType.houses,
                    text:     I18n.t('objectPools'),
                    route:    Routes.objectPools,
                    isActive: isObjectPoolRoute,
                    onClick:  onNavItemClicked(Routes.objectPools),
                },
            ];
        },
        [currentRoute],
    );
    const bottomNavigationItems = useMemo(
        () => {
            return [
                {
                    iconType: IconType.rocket,
                    text:     I18n.t('guide'),
                    route:    Routes.guide,
                    isActive: currentRoute === Routes.guide,
                    onClick:  onNavItemClicked(Routes.guide),
                },
            ];
        },
        [currentRoute],
    );

    return {
        bottomNavigationItems,
        navigationItems,
    };
};

export default useNavigationItems;
