//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes    from '@components/PropTypes';
import Button       from '@stateless/atomic/Button';
import ButtonType   from '@stateless/atomic/Button/ButtonType';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';

import styles from './styles.module.scss';

const propTypes = {
    buttonIconType:  PropTypes.iconType,
    buttonText:      PropTypes.string,
    content:         PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    onButtonClicked: PropTypes.func,
    title:           PropTypes.string,
};

const CtaBanner = ({
    buttonText      = '',
    buttonIconType  = null,
    content         = '',
    onButtonClicked = _.noop,
    title           = '',
}) => {
    function renderText(text) {
        return (
            <p>
                {text}
            </p>
        );
    }

    function renderParagraphs(paragraphs) {
        return _.map(paragraphs, renderText);
    }

    function renderTextOrParagraphs() {
        if (_.isString(content)) {
            return renderText(content);
        }

        return renderParagraphs(content);
    }

    return (
        <div className={styles.ctaBanner}>
            <Headline
                title={title}
                type={HeadlineType.headline2}
            />
            {renderTextOrParagraphs()}
            <Button
                text={buttonText}
                type={ButtonType.edgy}
                iconLeft={buttonIconType}
                onClick={onButtonClicked}
            />
        </div>
    );
};

CtaBanner.propTypes = propTypes;

export default CtaBanner;
